import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import { Typography, useMediaQuery, useTheme, Paper } from '@mui/material';
import Button from '@mui/material/Button';
import { amber } from '@mui/material/colors';
import { DataGrid } from '@mui/x-data-grid';
import { useNewPromoContext } from './NewPromoContextProvider';
import { requests } from '../../../../core/ApiService';
import { ApiPaths } from '../../../../core/PathConstants.js';
import ConfirmationPopup from '../../../../core/ConfirmPopup';
import dayjs from "dayjs";
import * as xlsx from 'xlsx';

const VehiclesExcludedModal = ({ country, open, onClose }) => {
    const [vehicles, setVehicles] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { setLoading, showAlert, promotion } = useNewPromoContext();
    const [error, setError] = useState('');
    const [rows, setRows]  = useState([]);
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
    const labelAlignment = isSmScreen ? 'left' : 'center';
    const onRefresh = () => {
    }
    const columns = [
        { field: 'exclusionType', headerName: 'Exclusion', align: 'left', minWidth: 90, flex: 1 },
        { field: 'vehicleNumber', headerName: 'Unit#', align: 'left', minWidth: 70, flex: 1 },
        { field: 'samClass', headerName: 'Sam Class', align: 'left', minWidth: 70, flex: 1 },
        { field: 'netBookValue', headerName: 'Net Book Value', align: 'left', minWidth: 85, flex: 1 },
        { field: 'acceptedByUtcDate', headerName: 'Accepted Vehicle By UTC Date', align: 'left', minWidth: 90, flex: 1 },
        { field: 'daysAtUtc', headerName: 'Days@UTC', align: 'left', minWidth: 60, flex: 1 },
        { field: 'takePrice', headerName: 'Take Price', align: 'left', minWidth: 70, flex: 1 },
        { field: 'askPrice', headerName: 'Ask Price', align: 'left', minWidth: 70, flex: 1 },
        { field: 'manufacturer', headerName: 'Manufacturer', align: 'left', minWidth: 90, flex: 1 },
        { field: 'modelYear', headerName: 'Model Year', align: 'left', minWidth: 60, flex: 1 },
        { field: 'engineModel', headerName: 'Engine Model', align: 'left', minWidth: 90, flex: 1 },
        { field: 'aged', headerName: 'Aged', align: 'left', minWidth: 60, flex: 1 },
        { field: 'msQualify', headerName: 'msQualify', align: 'left', minWidth: 60, flex: 1 }

    ];

    useEffect(() => {
            const fetchData = async () => {
                setLoading(true);
                try {
                    const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/excluded-vehicles-details`);
                    console.log(data);
                    if (data?.length <= 0) {
                        showAlert(`No Vehicles found for ${promotion.promotionKey}! Please check exclusions and pricing selections`, 'error');
                    } else {
                        setVehicles(data);
                    }
                } catch (err) {
                    showAlert(`Process failed while retrieving excluded vehicles for promotion ${promotion.promotionKey}!`, 'error');
                } finally {
                    setLoading(false);
                }
            }
    
            fetchData();
    
        }, [country, promotion.promotionId, promotion.promotionKey, setLoading, showAlert]);

    useEffect(() => {
            const fetchData = () => {
                let rows = vehicles.map(row => ({
                    id: row.vehicleNumber,
                    exclusionType: row.exclusionType,
                    vehicleNumber: row.vehicleNumber,
                    samClass: row.samClass,
                    netBookValue: row.netBookValue,
                    acceptedByUtcDate: row.dateAcceptedByUtc,
                    daysAtUtc: row.daysAtUtc,
                    takePrice: row.takePrice,
                    askPrice: row.askPrice,
                    manufacturer: row.vehicleManufacturer,
                    modelYear: row.modelYear,
                    engineModel: row.engineModel,
                    aged: row.isAged,
                    msQualify: row.msQualify
                }))
    
                setRows({ rows });
            }
    
            fetchData();
    
        }, [vehicles])  
        
        const handleConfirm = (isCancel) => {
            setConfirmOpen(false);
            if (isCancel) {
                onClose('cancel');
            }
        };

    const Title1 = [ ["", "", "", "", "", `${promotion.promotionKey}`] ]
    const Title2 = [
        ["", "", "", "", "", `${dayjs(promotion.startDateTime).format('MM/DD/YYYY hh:mm A')}`, "",
            `${dayjs(promotion.endDateTime).format('MM/DD/YYYY hh:mm A')}`]
    ]

    const Heading = [
        ['Exclusion Type', 'Unit#', 'Sam Class', 'Net Book Value', 'Accepted Vehicle By UTC Date', 'Days@Utc',
             'Take Price', 'Ask Price', 'Manufacturer', 'Model Year', 'Engine Model', 'Aged', 'MS Qualify']
    ];

    const handleExcelDownload = () => {

        if (Array(rows.rows)) {
            for (let key in rows.rows) {
                delete rows.rows[key].id;
          }
        }

        // Create a new workbook
        const wb = xlsx.utils.book_new();

        // Convert the vehicles data to a worksheet
        const ws = xlsx.utils.json_to_sheet(rows.rows, { origin: 'A4', skipHeader: true });

        xlsx.utils.sheet_add_aoa(ws, Title1, { origin: 'A1' });
        xlsx.utils.sheet_add_aoa(ws, Title2, { origin: 'A2' });
        xlsx.utils.sheet_add_aoa(ws, Heading, { origin: 'A3' });

        xlsx.utils.book_append_sheet(wb, ws, 'Excluded Vehicles');

        // Save and download the Excel file
        xlsx.writeFileXLSX(wb, `${promotion.promotionKey}.xlsx`);

    };

    const handleCSVDownload = () => {
    
            if (Array(rows.rows)) {
                for (let key in rows.rows) {
                    delete rows.rows[key].id;
                }
            }
    
            const wb = xlsx.utils.book_new();
            const ws = xlsx.utils.json_to_sheet(rows.rows, { origin: 'A4', skipHeader: true });
    
            xlsx.utils.sheet_add_aoa(ws, Title1, { origin: 'A1' });
            xlsx.utils.sheet_add_aoa(ws, Title2, { origin: 'A2' });
            xlsx.utils.sheet_add_aoa(ws, Heading, { origin: 'A3' });
    
            xlsx.utils.book_append_sheet(wb, ws, 'Excluded Vehicles');
            xlsx.writeFile(wb, `${promotion.promotionKey}.csv`, { bookType: 'csv' });
    
        };

    return( 
    <Dialog open={open} onClose={onClose} maxWidth="l" fullWidth>
        <Paper>
                <div style={{ height: 640, width: '100%' }}>
                    <div align="right" style={{ border: 0, width: '100%' }}>
                        <Typography style={{ margin: 3, textAlign: 'center' }}>Vehicles Excluded for <b>{promotion.promotionKey}</b></Typography>
                        <Typography variant="subtitle1" align={labelAlignment}>{dayjs(promotion.startDateTime).format('MM/DD/YYYY hh:mm A')} - {dayjs(promotion.endDateTime).format('MM/DD/YYYY hh:mm A')}</Typography>
                        <Button variant='contained' sx={{ backgroundColor: amber[500] }} style={{ marginRight: '16px' }} onClick={handleExcelDownload}>Download Excel</Button>
                        <Button variant='contained' sx={{ backgroundColor: amber[500] }} style={{ marginRight: '16px' }} onClick={handleCSVDownload}>Download CSV</Button>
                    </div>
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            initialState={{
                                pagination: {
                                    pageSize: 500,
                                },
                                filter: {
                                    filterModel: {
                                        items: [{ columnField: 'vehicleId', operatorValue: 'startsWith', value: '' }],
                                    },
                                },
                            }}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-columnHeaders':{
                                    backgroundColor: '#9e9e9e',
                                },
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    whiteSpace: 'break-spaces',
                                    lineHeight: 1,
                                },
                                '& .MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
                                    pl: 1,
                                },
                            }}
                            rowsPerPageOptions={[25, 50, 100, 500]}
                            rows={rows.rows} columns={columns} components={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
                        />
                    </div>    
                <div>
                    <div align="center" >
                        <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={onClose}>Back</Button>
                        <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={() => setConfirmOpen(true)}>Cancel</Button>
                        <Button variant='contained' sx={{ backgroundColor: amber[500] }} style={{ marginRight: '16px' }} onClick={onRefresh} disabled={!!error}>Refresh</Button>
                    </div>
                </div>
            </div>
            <ConfirmationPopup
                    open={confirmOpen}
                    onClose={() => handleConfirm()}
                    onConfirm={() => handleConfirm(true)}
                    message="Are you sure you want to cancel all modifications? Data will not be saved!"
                />
        </Paper>
    </Dialog>

)};

export default VehiclesExcludedModal