import React, { useCallback, useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { useNewPromoContext } from './NewPromoContextProvider';
import { Typography, useMediaQuery, useTheme, Checkbox, Paper } from '@mui/material';
import { requests } from '../../../../core/ApiService';
import ConfirmationPopup from '../../../../core/ConfirmPopup';
import { useHistory } from 'react-router-dom';
import { ApiPaths, ApplicationPaths } from '../../../../core/PathConstants.js';
import dayjs from "dayjs";
import { amber } from '@mui/material/colors';
import * as xlsx from 'xlsx';
import { DataGrid } from '@mui/x-data-grid';
import VehiclesExcludedModal from './VehiclesExcludedModal.js';

const VehiclesIncludedModal = ({ country, open, onClose }) => {

    const [vehicles, setVehicles] = useState([]);
    const [confirmOpen, setConfirmOpen] = useState(false);
    const { setLoading, showAlert, promotion } = useNewPromoContext();
    const [excludedVehiclesModalOpen, setExcludedVehiclesModalOpen] = useState(false);
    const [error, setError] = useState('');  //Vehicles
    const [rows, setRows] = useState([]);

    const history = useHistory();
    const theme = useTheme();
    const isSmScreen = useMediaQuery(theme.breakpoints.down('md'));
    const labelAlignment = isSmScreen ? 'left' : 'center';

    const Title1 = [ ["", "", "", "", "", "", "", `${promotion.promotionKey}`] ]
    const Title2 = [
        ["", "", "", "", "", "", "", `${dayjs(promotion.startDateTime).format('MM/DD/YYYY hh:mm A')}`, "",
            `${dayjs(promotion.endDateTime).format('MM/DD/YYYY hh:mm A')}`]
    ]

    const Heading = [
        ['Unit#', 'Promotion Pricing', 'Country', 'Campaign', 'Location Code', 'Location Name', 'Sam Class', 'T/T/T', 'Mdl_Yr',
            'Odometer', 'Take', 'Ask', 'Discount', 'Days@Utc', 'DOS', 'Age Bucket', '% of Take', 'FBO']
    ];

    const handleConfirm = (isCancel) => {
        setConfirmOpen(false);
        if (isCancel) {
            onClose('cancel');
        }
    };

    const goToPromoHomePage = useCallback(() => {
        history.replace(`/${country}${ApplicationPaths.NewPromo}`);
    }, [country, history]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const { data } = await requests.get(`${country}/${ApiPaths.NewPromo}/${promotion.promotionId}/vehicles`);
                console.log(data);
                if (data?.length <= 0) {
                    showAlert(`No Vehicles found for ${promotion.promotionKey}! Please check exclusions and pricing selections`, 'error');
                } else {
                    setVehicles(data);
                }
            } catch (err) {
                showAlert(`Process failed while retrieving vehicles for promotion ${promotion.promotionKey}!`, 'error');
            } finally {
                setLoading(false);
            }
        }

        fetchData();

    }, [country, promotion.promotionId, promotion.promotionKey, setLoading, showAlert]);


    const onRefresh = () => {
    }

    const handleClick = (rows, event) => {
        //let row = rows.row;

        //if (event.target.checked) {
        //    excludedVehicles.push({ "VehicleId": row.vehicleNumber })
        //}
        //else {
        //    excludedVehicles.pop(row.vehicleNumber);
        //}

        //if (excludedVehicles.length > 0) {
        //    setError('');
        //}
        //else {
        //    setError("Vehicles must be selected to exclude from the list");
        //}
    }


    const handleExcelDownload = () => {

        if (Array(rows.rows)) {
            for (let key in rows.rows) {
                delete rows.rows[key].id;
          }
        }

        // Create a new workbook
        const wb = xlsx.utils.book_new();

        // Convert the vehicles data to a worksheet
        const ws = xlsx.utils.json_to_sheet(rows.rows, { origin: 'A4', skipHeader: true });

        xlsx.utils.sheet_add_aoa(ws, Title1, { origin: 'A1' });
        xlsx.utils.sheet_add_aoa(ws, Title2, { origin: 'A2' });
        xlsx.utils.sheet_add_aoa(ws, Heading, { origin: 'A3' });

        xlsx.utils.book_append_sheet(wb, ws, 'Vehicles');

        // Save and download the Excel file
        xlsx.writeFileXLSX(wb, `${promotion.promotionKey}.xlsx`);

    };

    const handleCSVDownload = () => {

        if (Array(rows.rows)) {
            for (let key in rows.rows) {
                delete rows.rows[key].id;
            }
        }

        const wb = xlsx.utils.book_new();
        const ws = xlsx.utils.json_to_sheet(rows.rows, { origin: 'A4', skipHeader: true });

        xlsx.utils.sheet_add_aoa(ws, Title1, { origin: 'A1' });
        xlsx.utils.sheet_add_aoa(ws, Title2, { origin: 'A2' });
        xlsx.utils.sheet_add_aoa(ws, Heading, { origin: 'A3' });

        xlsx.utils.book_append_sheet(wb, ws, 'Vehicles');

        //xlsx.writeFile(wb, `${promotion.promotionKey}.csv`, { bookType: 'csv', FS: ';' });
        xlsx.writeFile(wb, `${promotion.promotionKey}.csv`, { bookType: 'csv' });

    };

     const handleCloseModal = useCallback((action, reason) => {
        // Don't close if clicked outside of the modal
        if (reason === 'backdropClick') {
            return;
        }
        setExcludedVehiclesModalOpen(false);

        if (action === 'cancel') {
            goToPromoHomePage();
        }
    }, [goToPromoHomePage]);

    useEffect(() => {
        const fetchData = () => {
            let rows = vehicles.map(row => ({
                id: row.vehicleNumber + row.locationId,
                vehicleNumber: row.vehicleNumber,
                promoPricing: row.promoPricing,
                country: row.country,
                campaignName: row.campaignName,
                locationId: row.locationId,
                locationName: row.locationName,
                samClass: row.samClass,
                ttt: row.ttt,
                modelYear: row.modelYear,
                odometer: row.odometer,
                takePrice: row.takePrice,
                askPrice: row.askPrice,
                discount: row.discount,
                daysAtUtc: row.daysAtUtc,
                dos: row.dos,
                ageBucket: row.ageBucket,
                percentageOfTake:row.percentageOfTake,
                vosaOrFbo: row.vosaOrFbo
            }))

            setRows({ rows });
        }

        fetchData();

    }, [vehicles])

    const columns = [
        { field: 'vehicleNumber',   headerName: 'Unit#',                align: 'left', minWidth: 70, flex: 1                   },
        { field: 'promoPricing', headerName: 'Promotion Pricing', align: 'left', type: 'number', minWidth: 85, flex: 1  },
        { field: 'country', headerName: 'Country', align: 'left', minWidth: 80, flex: 1                   },
        { field: 'campaignName', headerName: 'Campaign', align: 'left', minWidth: 100, flex: 1                   },
        { field: 'locationId', headerName: 'Location Code', align: 'left', type: 'number', minWidth: 90, flex: 1  },
        { field: 'locationName', headerName: 'Location Name', align: 'left', minWidth: 100, flex: 1                   },
        { field: 'samClass', headerName: 'Sam Class', align: 'left', type: 'number', minWidth: 70, flex: 1  },
        { field: 'ttt', headerName: 'T/T/T', align: 'left', minWidth: 70, flex: 1                                              },
        { field: 'modelYear', headerName: 'Mdl_Yr', align: 'left', minWidth: 70, flex: 1                   },
        { field: 'odometer', headerName: 'Odometer', align: 'left', type: 'number', minWidth: 90, flex: 1  },
        { field: 'takePrice', headerName: 'Take', align: 'left', type: 'number', minWidth: 70, flex: 1  },
        {
            field: 'askPrice', headerName: 'Ask', align: 'left', type: 'number', minWidth: 70, flex: 1
            //renderCell: (params) => (
            //    params.row.askPrice > 4000 ? <p style={{ 'color': 'red' }} > {params.row.askPrice} </p> : params.row.askPrice
            //),
        },
        { field: 'discount', headerName: 'Discount', align: 'left', type: 'number', minWidth: 90, flex: 1 },
        { field: 'daysAtUtc', headerName: 'Days@UTC', align: 'left', type: 'number', minWidth: 90, flex: 1 },
        { field: 'dos', headerName: 'DOS', minWidth: 100, flex: 1                                                  },
        { field: 'ageBucket', headerName: 'Age Bucket', minWidth: 90, flex: 1                                                 },
        { field: 'percentageOfTake', headerName: '% of Take', align: 'left', type: 'number', minWidth: 70, flex: 1 },
        { field: 'vosaOrFbo', headerName: 'FBO', align: 'left', minWidth: 70, flex: 1                  },
        { field: '',                headerName: 'Remove',               
            renderCell: (params) => (
                <Checkbox name="Enabled" onChange={(event) => handleClick(params, event)} />
            ),

        }
    ];

    return (
        <Dialog open={open} onClose={onClose} maxWidth="l" fullWidth >
            <Paper>
                <div style={{ height: 640, width: '100%' }}>

                    <div align="right" style={{ border: 0, width: '100%' }}>
                        <Typography style={{ margin: 3, textAlign: 'center' }}>Vehicles Included for <b>{promotion.promotionKey}</b></Typography>
                        <Typography variant="subtitle1" align={labelAlignment}>{dayjs(promotion.startDateTime).format('MM/DD/YYYY hh:mm A')} - {dayjs(promotion.endDateTime).format('MM/DD/YYYY hh:mm A')}</Typography>
                        <Button variant='contained' sx={{ backgroundColor: amber[500] }} style={{ marginRight: '16px' }} onClick={handleExcelDownload}>Download Excel</Button>
                        <Button variant='contained' sx={{ backgroundColor: amber[500] }} style={{ marginRight: '16px' }} onClick={handleCSVDownload}>Download CSV</Button>
                        <Button variant='contained' sx={{ backgroundColor: amber[500] }} onClick={() => setExcludedVehiclesModalOpen(true)}>View Exclusions</Button>
                        {excludedVehiclesModalOpen && <VehiclesExcludedModal country={country} open={excludedVehiclesModalOpen} onClose={ handleCloseModal} /> }
                    </div>
                    <div style={{ height: 600, width: '100%' }}>
                        <DataGrid
                            initialState={{
                                pagination: {
                                    pageSize: 500,
                                },
                                filter: {
                                    filterModel: {
                                        items: [{ columnField: 'vehicleId', operatorValue: 'startsWith', value: '' }],
                                    },
                                },
                            }}
                            disableColumnMenu
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                    whiteSpace: 'break-spaces',
                                    lineHeight: 1,
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                                    pl: 1,
                                },
                            }}
                            rowsPerPageOptions={[25, 50, 100, 500]}                            
                            rows={rows.rows} columns={columns} components={{ toolbar: { printOptions: { disableToolbarButton: true } } }}
                        />
                    </div>

                    <div>
                        <div align="center" >
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={onClose}>Back</Button>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={() => setConfirmOpen(true)}>Cancel</Button>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} disabled>Edit Promo</Button>
                            <Button variant='contained' color='inherit' style={{ marginRight: '16px' }} onClick={goToPromoHomePage} >Save For Later</Button>
                            <Button variant='contained' sx={{ color: 'primary' }} style={{ marginRight: '16px' }}  >Publish</Button>
                            <Button variant='contained' sx={{ backgroundColor: amber[500] }} style={{ marginRight: '16px' }} onClick={onRefresh} disabled={!!error}>Refresh</Button>
                        </div>
                    </div>

                </div>

                <ConfirmationPopup
                    open={confirmOpen}
                    onClose={() => handleConfirm()}
                    onConfirm={() => handleConfirm(true)}
                    message="Are you sure you want to cancel all modifications? Data will not be saved!"
                />

            </Paper>
        </Dialog>
    );

};

export default VehiclesIncludedModal;